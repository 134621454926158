<template>
    <v-row :class="type && type == 'selector' ? 'mediatheque white mx-0' : 'mediatheque white mx-0 mt-0'" style="height:100%; overflow:hidden; position:relative;" 
        @dragenter.prevent="!dragElem && folderUploadable ? dragTest('enter') : ''" 
        @dragover.prevent="!dragElem && folderUploadable ? dragTest('over') : ''">
        <div class="dropDiv" v-show="dragover && !dragElem" draggable
             @drop.prevent="onDropFiles($event)"
             @dragleave.prevent="dragover = false"
             @dragend.prevent="dragover =false"
             @dragstart.prevent>
            <div class="text" v-if="multiple">{{$t('DropYourFilesLabel')}}</div>
            <div class="text" v-else>{{$t('DropYourFileLabel')}}</div>
        </div>
        <v-col cols="2" class="folderTree" style="height:100%;">
            <div style="height:calc(100% - 70px); overflow-y:auto;">
                <v-treeview
                    :items="folderList"
                    :expand-icon="'$dropdown'"
                    v-model="tree"
                    :active="tree"
                    activatable
                    hoverable
                    return-object
                    :item-key="'id'"
                    item-text="title"
                    open-all
                    @update:active="onOpen"
                    :key="treeKey"
                >   
                    <template v-slot:label="{ item, open, active }">
                        <div :draggable="!item.hasOwnProperty('root') && item.id != 'trash' ? true : false"
                            :class="(overElem && overElem.id == item.id) ? 'hoverdrag' : ''"
                             @click="active ? $event.stopPropagation() : null"
                            @dragstart.self="pickupElem($event, item);"
                            @drop="moveElem($event, item)" 
                            @dragover.prevent="showDropPlace($event, item);" 
                            @dragend.prevent="dragEndClear();"
                            @dragenter.prevent>
                            <div v-if="!item.hasOwnProperty('root')">
                                {{ item.title }}
                                <v-tooltip content-class="white--tooltip" v-if="!item.is_editable && !item.private && item.holder && item.holder[0] != userEntity" right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-avatar size="10" color="#347ab6" class="ml-2" v-bind="attrs" v-on="on">
                                        </v-avatar>
                                    </template>
                                    <span>{{ folderEntities[item.holder[0]] }}</span>
                                </v-tooltip>
                                <v-tooltip v-if="item.is_editable && item.holder && item.holder[0] != userEntity" right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-avatar size="10" color="#e0e0e0" class="ml-2" v-bind="attrs" v-on="on">
                                        </v-avatar>
                                    </template>
                                    <span>{{ folderEntities[item.holder[0]] }}</span>
                                </v-tooltip>
                            </div>
                            <div v-else style="opacity:0;">RACINERACINE</div>
                        </div>
                    </template>
                    <template v-slot:prepend="{ item, open }">
                        <div class="folderIcon mr-2" 
                            v-if="!item.hasOwnProperty('root') && item.id != 'trash'"
                            :draggable="!item.hasOwnProperty('root') && item.id != 'trash' ? true : false"
                            :class="(overElem && overElem.id == item.id) ? 'hoverdrag' : ''"
                             @click="active ? $event.stopPropagation() : null"
                            @dragstart.self="pickupElem($event, item);"
                            @drop="moveElem($event, item)" 
                            @dragover.prevent="showDropPlace($event, item);" 
                            @dragend.prevent="dragEndClear();"
                            @dragenter.prevent
                            >
                            <v-icon color="#F8D775" size="18">
                                {{ open ? '$folderopen_d' : '$folder_d' }}
                            </v-icon>
                            <v-avatar size="14" class="lightgrey avatarFolder" v-if="!item.is_editable && !item.private && item.holder && item.holder[0] && item.holder[0] != userEntity">
                                <v-icon size="10" color="#347ab6">
                                    {{ '$lock_d' }}
                                </v-icon>
                            </v-avatar>
                            <v-avatar size="14" class="lightgrey avatarFolder" v-if="item.is_editable && item.private && item.holder && item.holder[0] && item.holder[0] != userEntity">
                                <v-icon size="10" :color="'darkgrey'">
                                    {{ '$usersecret_d' }}
                                </v-icon>
                            </v-avatar>
                            <v-avatar size="14" class="lightgrey avatarFolder" v-if="item.private && item.holder && item.holder[0] && item.holder[0] == userEntity">
                                <v-icon size="10" :color="'darkgrey'">
                                    {{ '$usersecret_d' }}
                                </v-icon>
                            </v-avatar>
                        </div>
                        <div v-else-if="item.id == 'trash'">
                            <v-icon size="18" color="error">
                                {{ '$trash_d' }}
                            </v-icon>
                        </div>
                        <div v-else>
                            <v-icon size="18">
                                {{ '$home_d' }}
                            </v-icon>
                        </div>
                    </template>
                </v-treeview>
            </div>
            <div style="height: 70px" v-if="quotas">
                
                <v-alert class="mb-0">
                    <div class="d-flex align-center">
                        <v-icon :color="quotas.alert == 'locked' ? 'error' : ''">$cloud_excalamation_d</v-icon>
                        <span class="ml-2" :class="quotas.alert == 'locked' ? 'error--text' : ''">{{$t('available_space')}} : {{ (quotas.used / 1000).toFixed(1) }} / {{ (quotas.quota / 1000) }} Go</span>
                    </div>
                    <v-progress-linear
                        class="mt-1 mb-1"
                        :color="quotas.alert == 'locked' ? 'black' : (quotas.alert == 'normal' ? 'primary' : quotas.alert)"
                        rounded
                        :value="quotas.percent"
                        ></v-progress-linear>
                </v-alert>
            </div>
        </v-col>
        <v-col cols="10" :class="!type || type == 'selector' ? '' : 'pa-0'" style="position:relative; height:100%;">
            <v-row class="headerMediatheque">
                <v-col cols="12">
                    <div class="ml-2 d-flex align-center">
                        <span class="folderIcon mr-2"  v-if="selectedFolder && selectedFolder[0] && !selectedFolder[0].hasOwnProperty('root')">
                            <v-icon color="#F8D775" size="42">
                                {{'$folderopen_d' }}
                            </v-icon>
                            <v-avatar size="20" class="lightgrey avatarFolder" v-if="!selectedFolder[0].is_editable && !selectedFolder[0].private && selectedFolder[0].holder && selectedFolder[0].holder[0] && selectedFolder[0].holder[0] != userEntity">
                                <v-icon size="12" color="#347ab6">
                                    {{ '$lock_d' }}
                                </v-icon>
                            </v-avatar>
                            <v-avatar size="20" class="lightgrey avatarFolder" v-if="selectedFolder[0].is_editable && selectedFolder[0].private && selectedFolder[0].holder && selectedFolder[0].holder[0] && selectedFolder[0].holder[0] != userEntity">
                                <v-icon size="12" :color="'darkgrey'">
                                    {{ '$usersecret_d' }}
                                </v-icon>
                            </v-avatar>
                            <v-avatar size="20" class="lightgrey avatarFolder" v-if="selectedFolder[0].private && selectedFolder[0].holder && selectedFolder[0].holder && selectedFolder[0].holder[0] && selectedFolder[0].holder[0] == userEntity">
                                <v-icon size="12" :color="'darkgrey'">
                                    {{ '$usersecret_d' }}
                                </v-icon>
                            </v-avatar>
                        </span>                   
                        <v-icon size="34" v-else>
                            {{'$home_d' }}
                        </v-icon>
                        <h3 class="ml-2 d-flex align-center" v-if="selectedFolder && selectedFolder[0] && !selectedFolder[0].hasOwnProperty('root')" @mouseenter="editButton = true" @mouseleave="editButton = false">
                            {{selectedFolder[0].title}} 
                            <editItemComponent v-if="selectedFolder && selectedFolder[0] && selectedFolder[0].is_editable && selectedFolder[0].id != 'trash'" :itemName="selectedFolder[0].title" :item="selectedFolder[0]" @editItem="editFolder" :key="'editFolder'" class="ml-2"/>
                            <v-chip class="ml-2 white--text" color="#347ab6" small v-if="!selectedFolder[0].is_editable && !selectedFolder[0].private && selectedFolder[0].holder && selectedFolder[0].holder[0] && selectedFolder[0].holder[0] != userEntity">
                                {{ folderEntities[selectedFolder[0].holder[0]] }}
                            </v-chip>
                            <v-chip class="ml-2" small v-if="selectedFolder[0].is_editable && selectedFolder[0].holder && selectedFolder[0].holder[0] && selectedFolder[0].holder[0] != userEntity">
                                {{ folderEntities[selectedFolder[0].holder[0]] }}
                            </v-chip>
                        </h3>
                        <v-spacer></v-spacer>
                        <v-text-field solo flat 
                            :label="$t('search')"  
                            v-model="searchString"
                            clearable
                            @click:clear="clearSearch"
                            @focus.stop=""
                            @keyup.enter="launchSearch" 
                            class="input-form mr-2" style="width:350px; flex-grow:0" hide-details dense>
                            <template slot="append">
                                <v-btn class="square" text @click="launchSearch">
                                    <v-icon>$magnify_l</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                        <v-btn depressed color="primary" @click="$refs.uploaderFilesInput.click()" v-if="(selectedFolder && selectedFolder[0]) && (selectedFolder[0].id == '0' || selectedFolder[0].is_editable)" class="white--text mr-2">
                            <v-icon color="white" class="mr-2">$cloudup_d</v-icon>
                            {{$t('uploadfile')}}
                        </v-btn>
                        <input
                            ref="uploaderFilesInput"
                            class="d-none"
                            type="file"
                            :multiple="true"
                            @change="onFileChange"
                            :accept="accept ? accept : ''"
                        >
                        <createFolderComponent v-if="(selectedFolder && selectedFolder[0]) && (selectedFolder[0].id == '0' || selectedFolder[0].is_editable)" :selectedFolderId="(selectedFolder && selectedFolder[0] && selectedFolder[0].id) ? selectedFolder[0].id : false" @folderCreated="refreshData" class="mr-2"/>
                        <deleteItemComponent v-if="selectedFolder && selectedFolder[0] && selectedFolder[0].id && selectedFolder[0].id != '0' && selectedFolder[0].is_editable" @deletedItem="deletedItem" :item="selectedFolder[0]" :type="'folder'" />
                    </div>
                </v-col>
            </v-row>
            <v-row :class="type && type == 'selector' ? 'px-6' : 'px-0 mt-0'" style="position:relative; height:calc(100% - 49px);">
                <div class="loader" v-if="loading" style="position:absolute; top:12px; left:0; width:100%; height:clac(100% + 12px); z-index:150; background-color:rgba(255,255,255,0.7); display:flex; align-items: center; justify-content: center;">
                    <div class="d-flex flex-column align-center justify-center">
                        <v-progress-circular
                            :size="50"
                            color="primary"
                            :indeterminate="loadingForUpload ? false : true"
                            :value="loadingForUpload ? progressFiles : 0"
                            ></v-progress-circular>
                        <div v-if="loadingForUpload" class="primary--text mt-2"><b>{{ $t('filesUploading') }}</b></div>
                    </div>
                </div>
                <v-col cols="9" :class="type && type == 'selector' ? '' : 'mt-3'" style=" height:100%; overflow-y:scroll;" v-scroll.self="scrollTest">
                    <v-row class="flex-grow-0 d-flex flex-wrap justify-space-around" :id="'innerMedia'+(id ? id : '')" v-if="medias && medias.length > 0">
                        <div v-for="(media, index) in medias" class="px-4 flex-shrink-0 mt-2" :key="media.id">
                            <v-card rounded="lg" :style="(overElem && overElem.id == media.id) ? 'background-color: #d0ebd1' : ''" class="no-shadow h-100 mediaCard" :color="(fileSelected.length > 0 && fileSelected.find((e) => e.id == media.id)) ? 'lightgrey' : ''"
                                draggable
                                @click="setFileSelected(media)"
                                @dragstart.self="pickupElem($event, media);"
                                @dragover.prevent="showDropPlace($event, media);"
                                
                                @drop.prevent="moveElem($event, media)"
                                @dragend.prevent="dragEndClear();"
                                >
                                <div class="hoverDivMedia"> 
                                    <v-avatar color="grey" v-if="!media.is_folder">
                                        <v-icon color="white" size="24">$magnify_l</v-icon>    
                                    </v-avatar>
                                </div>
                                <v-card-text class="d-flex justify-center"> 
                                    <div class="d-flex justify-center flex-column align-center">
                                        <div v-if="!media.is_folder" style="position:relative">
                                            <div style="position:absolute; z-index:10; bottom:5px;">
                                                <v-img :src="media.type_picto" height="40" width="40"></v-img>
                                            </div>
                                            <v-img :src="media.thumb" class="lightgrey" height="150" width="150"></v-img>
                                        </div>
                                        <div v-else style="width:150px;" class="folderIcon">
                                            <v-icon size="150" color="#F8D775">$folder_d</v-icon>
                                            <v-avatar size="32" class="lightgrey avatarFolder" v-if="!media.is_editable && !media.private && media.holder && media.holder[0] && media.holder[0] != userEntity">
                                                <v-icon size="20" color="#347ab6">
                                                    {{ '$lock_d' }}
                                                </v-icon>
                                            </v-avatar>
                                            <v-avatar size="32" class="lightgrey avatarFolder" v-if="media.is_editable && media.private && media.holder && media.holder[0] && media.holder[0] != userEntity">
                                                <v-icon size="20" :color="'darkgrey'">
                                                    {{ '$usersecret_d' }}
                                                </v-icon>
                                            </v-avatar>
                                            <v-avatar size="32" class="lightgrey avatarFolder" v-if="media.private && media.holder && media.holder[0] && media.holder[0] == userEntity">
                                                <v-icon size="20" :color="'darkgrey'">
                                                    {{ '$usersecret_d' }}
                                                </v-icon>
                                            </v-avatar>
                                        </div>
                                        <div class="mediathequeTitle text-center"  style="min-width:160px; max-width:160px;">{{ media.title && media.title.length > 15 ? media.title.substr(0, 15)+'...' : media.title }}</div>
                                    </div>
                                </v-card-text>
                            </v-card> 
                        </div>
                        <div v-for="(blank, index) in supElement" class="mt-2" :key="'blank_'+index" style="width:224px;">

                        </div>
                    </v-row>
                    <v-row v-else style="height:100%;">
                        <v-col cols="12" class="d-flex align-center justify-center">
                            <v-alert border='left' :icon="'$info_s'" width="400" type="info" text class="mb-0">
                                <h6 style=" padding-bottom: 0;" class="info--text">{{ $t('noMediaFound') }}</h6>
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="3" :class="type && type == 'selector' ? '' : 'mt-3'" style="border-left:1px solid #d2d2d2; overflow-y: auto; height:100%;">
                    <v-row v-if="!multiple && fileSelected && fileSelected.length > 0">
                        <v-col cols="12" class="text-right d-flex align-center justify-center" style="position: relative;">
                            <v-img :src="fileSelected[0].thumb" class="lightgrey flex-grow-0" contain width="100%" height="340"></v-img>
                            <div class="actions d-flex" style="position:absolute; bottom:15px; right:10px;">
                                <moveMediaComponent :mode="(selectedFolder && selectedFolder[0] && selectedFolder[0].id == 'trash') ? 'restore' : 'move'" v-if="fileSelected[0].is_editable" class="mr-2" :item="fileSelected[0]" @movedItem="refreshData(false)"/>
                                <editItemComponent class="mr-2" v-if="fileSelected[0].is_editable && selectedFolder && selectedFolder[0] && selectedFolder[0].id != 'trash'" :item="fileSelected[0]" :itemName="fileSelected[0].title" @editItem="editMedia"/>
                                <deleteItemComponent class="mr-2" v-if="fileSelected[0].is_editable" :item="fileSelected[0]" @deletedItem="deletedItem" :type="'media'" :mode="(selectedFolder && selectedFolder[0] && selectedFolder[0].id == 'trash') ? 'hard' : 'soft'"/>          
                            </div>
                        </v-col>
                        <v-col cols="12" class=" detailMedia">
                            <h5 class="mb-4 ml-1">{{ fileSelected[0].title }}</h5>
                            <ul>
                                <li>{{$t('reference')}} : #M{{ fileSelected[0].id }}</li>
                                <li v-if="fileSelected[0].fileinfo && fileSelected[0].fileinfo.image">{{$t('dimensions')}} : {{ fileSelected[0].fileinfo.image.x }} x {{ fileSelected[0].fileinfo.image.y }}px</li>
                                <li v-if="fileSelected[0].fileinfo && fileSelected[0].fileinfo.size">{{ $t('weight') }} : {{ calculeWeight(fileSelected[0].fileinfo.size) }}</li>
                                <li>{{ $t('type') }} : {{ fileSelected[0].mime }}</li>
                                <li>{{ $t('import_date') }} : {{ timestampToDate(fileSelected[0].time, "long") }}</li>
                                <li v-if="fileSelected[0].url">{{ $t('url') }} : <a target="_blank" :href="fileSelected[0].url">{{ fileSelected[0].url.length > 40 ? shortUrl(fileSelected[0].url) : fileSelected[0].url}}</a></li>
                            </ul>
                        </v-col>
                    </v-row>
                    <v-row v-else-if="multiple && fileSelected && fileSelected.length > 0">
                        <v-col cols="12" v-for="(media, index) in fileSelected" :key="'fileindex_'+index">
                            <div class="d-flex align-center">
                                <v-img :src="media.thumb" class="lightgrey flex-grow-0" contain width="80" height="100"></v-img>
                                <div class="flex-shrink-1">
                                    <ul>
                                        <li v-if="media.title">{{media.title && media.title.length > 15 ? media.title.substr(0, 15)+'...' : media.title }}</li>
                                        <li>{{$t('reference')}} : #M{{ media.id }}</li>
                                        <li v-if="media.fileinfo && media.fileinfo.image">{{$t('dimensions')}} : {{ media.fileinfo.image.x }} x {{ media.fileinfo.image.y }}px</li>
                                        <li>{{ $t('type') }} : {{ media.mime }}</li>
                                        <li>{{ $t('url') }} : <a target="_blank" :href="media.url">{{ media.url.length > 40 ? shortUrl(media.url) : media.url}}</a></li>
                                    </ul>
                                </div>
                                <v-icon small color="error" @click="deselectFile(media)">$close</v-icon>
                            </div>
                            <v-divider class="mt-4"></v-divider>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col>
                            {{ $t('noMediaSelected') }}
                        </v-col>
                    </v-row>
                    <transition name="fade">
                    <v-alert
                        v-if="errorFile != ''"
                        dense
                        border="left"
                        color="error"
                        class="white--text"
                        width="300"
                        style="position:absolute; bottom:0; right:50px;"
                        >
                        <div class="d-flex align-center">
                            <v-icon class="mr-3" color="white">$warning_s</v-icon>
                            <div><b>{{ $t(errorFile) }}</b></div>
                        </div>
                    </v-alert>
                </transition>
                </v-col>
            </v-row>
            
        </v-col>
    </v-row>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import timestampToDate from '@/mixins/dateManipulation'
import createFolderComponent from './createFolderComponent.vue'
import editItemComponent from './editItemComponent.vue';
import deleteItemComponent from './deleteItemComponent.vue';
import moveMediaComponent from './moveMediaComponent.vue';

export default {
    name:"mediathequeComponent",
    props:['id', 'type', 'multiple', 'value', 'accept'],
    components: {
        createFolderComponent, editItemComponent, deleteItemComponent, moveMediaComponent
    },
    mixins:[timestampToDate],
    data(){
        return{
            dragover: false,
            editButton: false,
            tree:[],
            treeKey:0,
            folderList: [],
            folderEntities : {},
            fileSelected: [],
            selectedFolder: null,
            from:0,
            limit:30,
            totalItems:0,
            searchString: "",
            filters: {}, 
            medias: [],
            openDetailsImage: false,
            loading: false,
            multipleUpload: false,
            filesToUpload: [],
            dragElem : null,
            overElem : null,
            quotas: {},
            errorFile: "",
            modulo: 0,
            supElement: 0,
            progressFiles: 0,
            loadingForUpload : false,
        }
    },
    created() {
        this.selectedFolder = this.folderList;
        if(this.value && this.value.length > 0 && typeof(this.value) != 'string'){
            this.fileSelected = this.value;
        }
        this.refreshData();
        
    },
    mounted(){
        if(this.value && this.value.length > 0 && typeof(this.value) != 'string'){
            this.fileSelected = this.value;
        }
    },
    watch: {
        errorFile(){
            setTimeout(() => {
                this.errorFile = ""
            }, 5000);
        },
    },
    computed: {
        userEntity(){
            return this.$store.state.auth.currentUser.profile.entity_id[0]
        },
        mediaUrl(){
            return this.$store.state.auth.currentUser.accountParameters.mediaUrl
        },
        apiKey(){
            return this.$store.state.auth.currentUser.accountParameters.mediaApiKey
        },
        folderUploadable(){
            let response = false;
            if(this.selectedFolder && this.selectedFolder[0] && this.selectedFolder[0].id != 'trash' && (this.selectedFolder[0].is_editable || this.selectedFolder[0].hasOwnProperty('root'))){
                response = true;
            }

            return response
        }
    },
    methods: {
        getModuloSize(){
            let element = document.getElementById('innerMedia'+(this.id ? this.id : ''));
            this.modulo = Math.floor(element.getBoundingClientRect().width / 224)
            this.supElement = this.modulo - (this.medias.length)%this.modulo
        },
        dragTest(type){
            console.log('dragTest', type)
            this.dragover = true;
        },
        
        clearSearch(){
            this.searchString = "";
            this.from = 0;
            this.searchMedias();
        },
        launchSearch(){
            this.from = 0;
            this.searchMedias();
        },
        refreshData(refreshSelected = true){
            this.loading = true;
            this.getQuota();
            setTimeout(() => {
                this.from = 0;
                this.getFolderList(refreshSelected);
                setTimeout(() => {
                    this.searchMedias();
                }, 200);
                this.loading = false;
            }, 500);   
           
        },
        deselectFile(media){
            this.fileSelected = this.fileSelected.filter((e) => e.id != media.id);
            if(this.type && this.type == 'selector'){
                this.$emit('unselectMedia', media);
            }
        },
        setFileSelected(media){
            if(media.is_folder){
                this.selectedFolder = [media];
                this.tree = [this.selectedFolder[0]];
                this.from = 0;
                this.treeKey++;
                this.searchMedias();
                return;
            }
            let isSelected = this.fileSelected.find((e) => e.id == media.id);
            if(isSelected){
                this.fileSelected = this.fileSelected.filter((e) => e.id != media.id);
                if(this.type && this.type == 'selector'){
                    this.$emit('unselectMedia', isSelected);
                }
                this.openDetailsImage = false;
            } else {
                if(this.multiple){
                    this.fileSelected.push(media);
                } else {
                    this.fileSelected = [media];
                }
                //this.fileSelected = [media];
                if(this.type && this.type == 'selector'){
                    this.$emit('selectMedia', media);
                }
                this.openDetailsImage = true;
            }
        },
        onOpen(item){
            if(this.type != 'selector')
                this.fileSelected = [];
            
            if(item.length == 0)
                this.selectedFolder = this.folderList;
            else   
                this.selectedFolder = item;
            this.from = 0;

            this.searchMedias();
        },
        scrollTest($evt){
            var elementTop = document.getElementById('innerMedia'+(this.id ? this.id : '')).getBoundingClientRect().top;
            var elementHeight = document.getElementById('innerMedia'+(this.id ? this.id : '')).getBoundingClientRect().height;
            var outerSize = $evt.srcElement.clientHeight
            if(elementTop < (outerSize - elementHeight +  $evt.srcElement.getBoundingClientRect().top + 10) && this.loading == false && this.medias.length < this.totalItems){
                this.loading = true;
                this.from = this.from + this.limit;
                this.searchMedias(true);
            }
        },
        searchMedias(add = false){
            let url = "mediatheque/mediaList";
            if(this.selectedFolder && this.selectedFolder[0] && this.selectedFolder[0].id == 'trash'){
                url = "mediatheque/trashList";
            }
            let jsonData = {
                's': this.searchString,
                'meta': this.filters,
                'from': this.from,
                'limit': this.limit,
                'folder_id': this.selectedFolder.length > 0 ? this.selectedFolder[0].id : 0
            };
            GenericDataService.postData(url, jsonData).then((response) => {
                this.totalItems = response.data.data.total;
                if(add){
                    this.medias = this.medias.concat(response.data.data.medias);
                } else {
                    this.medias = response.data.data.medias;
                }
                setTimeout(() => {
                    this.getModuloSize();
                }, 500);
                this.loading= false
            });
        },
        onDropFiles($event){
            this.dragover = false;
            this.loading= true
            this.filesToUpload = [];
            $event.dataTransfer.files.forEach(element => {
                if((element.size / 1000)/1000 > 20) {
                    this.loading= false
                    this.filesToUpload = [];
                    this.errorFile = "fileSizeErrorMoreThan20"
                    return;
                }
                this.filesToUpload.push(element)
            });
            // if(!this.multiple && this.filesToUpload.length > 1){
            //     this.errorFile = "noMoreThanOneFileError"
            //     this.filesToUpload = [];
            //     this.loading= false
            // } else {
                this.errorFile = ""
                this.uploadMedias() 
            // }
            
        },
        onFileChange() {
            this.loading= true
            this.$refs.uploaderFilesInput.files.forEach(element =>{
                if((element.size / 1000)/1000 > 20) {
                    this.loading= false
                    this.filesToUpload = [];
                    this.errorFile = "fileSizeErrorMoreThan20"
                    return;
                }
                this.filesToUpload.push(element)
            });
            this.uploadMedias();
        },
        uploadMedias() {
            let totalFiles = this.filesToUpload.length
            let count = 0
            this.loadingForUpload = true;
            //this.loading= true
            this.filesToUpload.forEach(element => {
                if (element) {
                    this.errorFile = ""
                    const reader = new FileReader
                    const vm = this
                    reader.onload = e => {
                        let jsonData = {
                            'title': element.name,
                            'base64': e.target.result,
                            'folder_id': this.selectedFolder.length > 0 ? this.selectedFolder[0].id : 0
                        };
                        GenericDataService.postData("mediatheque/uploadMedia", jsonData, {
                            onUploadProgress: (progressEvent) => {
                                if (progressEvent.lengthComputable) {
                                    this.progressFiles = (progressEvent.loaded * 100)/progressEvent.total;
                                }
                            }
                        }).then((response) => {
                            if(!response.data.data.success){
                                this.errorFile = (response.data.data.message).replace(' ', '')
                                this.loading= false
                                this.filesToUpload = []
                                this.loadingForUpload = false;
                                return;
                            }
                            count ++;
                            if(count == totalFiles){
                                this.loading= false
                                this.filesToUpload = []
                                this.loadingForUpload = false;
                                this.refreshData(false);
                            }
                        });
                    }
                    reader.readAsDataURL(element)
                } else {
                    this.errorFile = this.$t("selectError")
                }
            });
            // setTimeout(() => {
            //     this.filesToUpload = []
            //     this.refreshData(false);
            // }, 500);
        },
        editFolder(folderName, priv){
           let jsonData = {
                'title': folderName,
                'id': this.selectedFolder[0].id,
                'private': priv
            };
            GenericDataService.postData("mediatheque/updateFolder", jsonData).then((response) => {
                this.selectedFolder[0] = response.data.data.folder;
                this.tree = [this.selectedFolder[0]];
                this.refreshData(false);
            });
        },
        editMedia(fileName){
            let jsonData = {
                'title': fileName,
                'id': this.fileSelected[0].id
            };
            GenericDataService.postData("mediatheque/updateMedia", jsonData).then((response) => {
                this.fileSelected[0].title = fileName;
                this.refreshData();
            });
        },
        deletedItem(item){
            this.fileSelected = [];
            if(item && item.is_folder)
                this.refreshData();
            else
                this.refreshData(false);
        },
        getFolderList(resetFolderSelected = true){
            GenericDataService.postData("mediatheque/getFolderList").then((response) => {
                this.folderList = [response.data.data.folders];
                this.folderEntities = response.data.data.entities;
                if(!this.type || this.type != 'selector'){
                    this.folderList.push({
                        is_folder: true,
                        id: 'trash',
                        title: this.$t('garbage')
                    })
                }
                if(resetFolderSelected)
                    this.selectedFolder = this.folderList;
                this.treeKey++;
            });
        },
        shortUrl(url){
            return url.substr(0, 20) + '...' + url.substr(-15);
        },
        calculeWeight(size){
            let weight = size / 1024;
            if(weight < 1024){
                return weight.toFixed(2) + ' Ko';
            } else {
                return (weight / 1024).toFixed(2) + ' Mo';
            }
        },
        pickupElem(event, media){
            event.dataTransfer.dropEffect = "move";
            this.dragElem = { ...media };
        },
        showDropPlace(event, media){
            //console.log('showDropPlace', event, media)
            if (media && this.dragElem && media.id !== this.dragElem.id && ((media.is_folder && media.is_editable) || media.root)) {
                this.overElem = { ...media };
            } else {
                this.overElem = null;
            }
        },
        moveElem(event, media){
            this.loading = true;
            if (media && this.dragElem && media.id !== this.dragElem.id && ((media.is_folder && media.is_editable) || media.root)) {
                let url = "mediatheque/updateMedia"

                if(this.dragElem.is_folder){
                    url = "mediatheque/updateFolder"
                    GenericDataService.postData(url, {id: this.dragElem.id, parent_id: (media.root ? '' : media.id)}).then((response) => {
                        this.refreshData();
                    }).catch((error) => {
                        this.loading = false;
                    });
                } else {
                    GenericDataService.postData(url, {id: this.dragElem.id, folder_id: (media.root ? '' : media.id)}).then((response) => {
                        this.refreshData();
                    }).catch((error) => {
                        this.loading = false;
                    });
                }
                 
            } else {
                this.loading = false;
            }
        },
        dragEndClear(event, media){
            this.dragElem = null;
            this.overElem = null;
        },
        getQuota(){
            GenericDataService.getData('mediatheque/getQuota').then((response) => {
                this.quotas = response.data.data;
            })
        }
    },
}
</script>
<style lang="scss">
.mediatheque {
    .dragging {
        cursor: grabbing !important;
    }
    .folderTree{
        background-color: #f9f9f9 !important;
        border-right:1px solid #d2d2d2 !important;
        
    }
    .folderIcon {
        position:relative;
        .avatarFolder {
            position:absolute;
            right: -5px;
            bottom:-5px;
        }
    }
    .detailMedia {
        ul {
            
            li {
                margin-bottom:5px;
            }
        }
    }
    .v-treeview-node__root {
        cursor:pointer;
    }
    .v-treeview-node__label {
        width:100%;
    }
    .hoverDivMedia {
        display: none;
        position:absolute;
        color: white;
        top:0;
        height:0;
        align-items:center;
        justify-content: center;
        background-color: rgba(240,240,240,0.2) !important;
        z-index:100;
        width:100%;
        height:100%;
        border-radius:10px;
    }
    .mediaCard:hover {
        background-color: #eee !important;
        .hoverDivMedia {
            display: flex;
        }
    }
    .headerMediatheque {
        border-bottom:1px solid #d2d2d2 !important;
    }
    .mediathequeTitle {
        word-break: break-all;
        padding:10px; 
        padding-bottom:0px;
    }
    .v-treeview-node__root:has(div > div > div.hoverdrag) {
        background-color:#d0ebd1 !important;
    }
    .dropDiv {
        background-color:rgba(208, 227, 255, 0.5);
        border: 2px solid #347ab6;
        margin:5px;
        position:absolute;
        top:0;
        left:0;
        width:calc(100% - 10px);
        height:calc(100% - 10px);
        border-radius: 10px;;
        .text {
            color: #347ab6 !important;
        }
    }
}

</style>